import type { GenericObject, FormOptions } from 'vee-validate'

export interface ApiFormOptions<TValues extends GenericObject> extends FormOptions<TValues> {
  onSubmit: {
    url: string
    method?: 'get' | 'post' | 'put' | 'patch' | 'delete'
  }
  onSuccess?: () => Promise<void>
}

export default function<TValues extends GenericObject = GenericObject>(options: ApiFormOptions<TValues>) {
  const { values, setErrors, handleSubmit, ...veeForm } = useForm<TValues>(options)

  const { submit: apiSubmit } = useApiSubmit(options.onSubmit)

  async function submit() {
    const { valid, errors } = await apiSubmit(values)

    if (!valid) {
      setErrors(errors)
    }
    else if (options.onSuccess) {
      await options.onSuccess()
    }
  }

  const onSubmit = handleSubmit(async () => {
    await submit()
  })

  return { values, setErrors, handleSubmit, ...veeForm, submit, onSubmit }
}
