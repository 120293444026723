import { FetchError } from 'ofetch'

type GenericObject = Record<string, any>

interface ApiSubmitOptions {
  url: string
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete'
}

export default function<TValues extends GenericObject = GenericObject> (options: ApiSubmitOptions) {
  const { $api } = useNuxtApp()

  async function send(values: TValues, headers?: HeadersInit) {
    try {
      await $api(options.url, {
        method: options.method ?? 'post',
        body: values,
        headers,
      })

      return {
        valid: true,
        errors: {},
      }
    }
    catch (error) {
      if (error instanceof FetchError && error.response?.status === 422) {
        return {
          valid: false,
          errors: formatApiValidationErrors(error.data.errors),
        }
      }
      else {
        throw error
      }
    }
  }

  async function validate(values: TValues, only?: (keyof TValues)[]) {
    const headers: HeadersInit = {
      Precognition: 'true',
    }

    if (only) {
      headers['Precognition-Validate-Only'] = only.join(',')
    }

    return send(values, headers)
  }

  async function submit(values: TValues) {
    return send(values)
  }

  return { validate, submit }
}
