<template>
  <div class="flex flex-col gap-sm">
    <div class="relative">
      <div
        v-if="icon"
        class="absolute inset-y-0 left-0 flex items-center pl-3.5"
      >
        <UiIcon
          :name="icon"
          class="size-5 text-gray-500"
        />
      </div>
      <input
        ref="input"
        v-model="value"
        v-bind="$attrs"
        class="w-full rounded-lg border bg-white text-base text-gray-900 shadow-xs placeholder:text-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-1 disabled:bg-gray-50 disabled:text-gray-500"
        :class="{
          'px-3 py-2': size === 'sm',
          'px-3.5 py-2.5': size === 'md',
          'pl-10': !!icon && size === 'sm',
          'pl-10.5': !!icon && size === 'md',
          'pr-9': errorMessage && size === 'sm',
          'pr-9.5': errorMessage && size === 'md',
          'border-error-300 focus-visible:outline-error-500': errorMessage,
          'border-gray-300 focus-visible:outline-brand-500': !errorMessage,
        }"
        :autofocus="autofocus"
      >
      <div
        v-if="errorMessage"
        class="absolute inset-y-0 right-0 flex items-center pr-3.5"
      >
        <UiIcon
          name="alert-circle"
          class="size-4 text-error-500"
        />
      </div>
    </div>
    <p
      v-if="errorMessage"
      class="text-sm text-error-600"
    >
      {{ errorMessage }}
    </p>
    <p
      v-else-if="hint"
      class="text-sm text-gray-600"
    >
      {{ hint }}
    </p>
  </div>
</template>

<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    size?: 'sm' | 'md'
    name: string
    icon?: string
    autofocus?: boolean
    hint?: string
  }>(),
  {
    size: 'md',
  },
)

const { value, errorMessage } = useField<string>(() => props.name)

const input = useTemplateRef<HTMLInputElement>('input')

onMounted(() => {
  if (props.autofocus) {
    input.value!.focus()
  }
})
</script>
